<template>
<div class="top">
  <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onClickLeft"></van-nav-bar>
</div>
</template>

<script>
  import { Toast,Dialog,NavBar,Icon} from 'vant'
  import { wxJSapiTicket } from './../api/login'
  import Store from 'store'

  export default {
    name: 'top',
    components: {
      NavBar,
      Icon
    },
    props: {
      title: {
        default: '',
      }
    },
    data () {
      return {
        
      }
    },
    created() {

    },
    mounted() {
        
    },
    computed: {

    },
    methods: {
      onClickLeft() {
        this.$router.push("/product")
      },
    }
  }
</script>

<style lang="less">
  .van-share-sheet__name{
    text-align: center;
  }
  .wrapper {
    display: block;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .wrapper .block{
    position:absolute;
    top:30px;
    left: 10px;
    font-size: 14px;
    color:#fff;
  }
</style>
