import Request from './request';

// crm 列表
export function crmProductIndex(params) {
  return Request({
    url: 'crm/product/index',
    method: 'post',
    data: params
  })
}

export function crmProductRead(params) {
  return Request({
    url: 'crm/product/read',
    method: 'post',
    data: params
  })
}

export function productItemIndex(params) {
  return Request({
    url: 'crm/product/itemIndex',
    method: 'post',
    data: params
  })
}

export async function crmProductNum(params){
  return Request({
    url: 'crm/product/getNum',
    method: 'post',
    data: params
  })
}

export function crmProductFeeIndex(data) {
  return Request({
    url: 'crm/product/feeIndex',
    method: 'post',
    data: data
  })
}

export async function getCurrentMessage(params){
  return Request({
    url:'/user/getCurrentMessage',
    method:'get',
    data:params,
  })
}

export function getUserInfo(params) {
  return Request({
    url: 'admin/users/read',
    method: 'post',
    data: params
  })
}

/**获得销售专员 */
export function crmBusinessUser(params) {
  return Request({
    url: 'crm/business/businessUser',
    method: 'post',
    data: params
  })
}


export function crmCategoryChilden(params) {
  return Request({
    url: 'crm/product/categoryChilden',
    method: 'post',
    data: params
  })
}

export function productCategoryIndex(params) {
  return Request({
    url: 'crm/product/categoryTreeList',
    method: 'post',
    data: params
  })
}

export function getParentCategory(params) {
  return Request({
    url: 'crm/product/categoryParent',
    method: 'post',
    data: params
  })
}
