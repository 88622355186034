import _ from 'lodash'
import moment from 'moment'

export function toFixNum(num, length=2, type='num') {
  switch (type) {
    case 'num':
      return _.round(num, length)
    case 'string':
      return _.round(num, length).toFixed(2)
  }
}

export function showPrice(num, length=2) {
  return _.round(num, length).toFixed(2)
}

export function showTime(time, format) {
  if(time){
    let res = moment.unix(Date.parse(time
      .replace(/\-/g,'\/')
      .replace(/[T|Z]/g,' ')
    )/1000).format(format || 'YYYY-MM-DD');
    return res
  }
}
export function showMonth(time, format) {
  if(time){
    let res = moment.unix(Date.parse(time
      .replace(/\-/g,'\/')
      .replace(/[T|Z]/g,' ')
    )/1000).format(format || 'YYYY-MM');
    return res
  }
}

export function showDate(time, format) {
  if(time){
    let res = moment(time).format(format || 'YYYY-MM-DD');
    return res
  }
}

export function checkDateEmpty(date) {
  return !date || date === '0001-01-01T00:00:00Z'|| date === '0001-01-01' || !moment(date).isValid()
}

export function toChinesNum(num) {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
    let strArr = temp.toString().split("").reverse();
    let newNum = "";
    for (var i = 0; i < strArr.length; i++) {
      newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
    }
    return newNum;
  }
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) noWan = "0" + noWan;
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}
